import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  data() {
    return {
      options: {
        legend: {
          display: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          custom: function(tooltip) {
            if (!tooltip) return
            tooltip.displayColors = false
          },
          callbacks: {
            label: function(tooltipItem, data) {
              return 'X :' + tooltipItem.xLabel
            },
            title: function(tooltipItem, data) {
              console.log(tooltipItem)
              return 'Y :' + tooltipItem[0].yLabel
            }
          }
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: '#5477A9',
                fontStyle: 'bold'
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                fontColor: '#5477A9',
                fontStyle: 'bold',
              }
            }
          ]
        }
      }
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
